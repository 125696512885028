
@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("Montserrat-Medium"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBold";   /*Can be any text*/
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
}