.collabPageSection1{
    width: 100%;
    background-image: url("../../assets/missionpageBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
}
.collabPageBg{
    width: 100%;
    height: 40vh;
    object-fit: cover;
    background-repeat: no-repeat;
    filter: brightness(.8);
}
.collabPageHeading{
    color: #fff;
    text-align: center;
    font-size: 60px;
    font-family: Bold;
    padding: 7%;
}
.collabPageSection2{
    width: 80%;
    padding: 2% 10%;
}
.collabPageSectionHeading{
    font-size: 25px;
    font-family: Bold;
    margin-bottom: 15px;
    color: #316412;
    text-align: center;
    margin: 10px;
}
.collagePageSectionPart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0px;
}
.collabPageSection2Content{
    width: 70%;
    font-size: 16px;
    font-family: Regular;
    margin-bottom: 15px;
    line-height: 28px;
}
.readMoreBtn{
    border: none;
    background-color: #316412;
    color: white;
    font-size: 15px;
    font-weight: 500;
    font-family: "Bold";
    padding: 10px 30px;
    border-radius: 10px;
    margin: 10px 0px;
}
.Link{
    text-decoration: none;
    color: #FFFFFF;
    font-family: Medium;
}
.collabPageSection2Image{
    width: 40%;
    height: 90%;
    margin-left: 5%;
    margin-top: 2%;
}
.collabPageSection3{
    width: 80%;
    padding: 2% 10%;
    background-color: #E5E5E5;
}
.collabPageSection4{
    width: 80%;
    padding: 2% 10%;
}
.collabPageSection5{
    width: 80%;
    padding: 2% 10%;
}
.collabPageCardsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-flow: wrap;
}
.collabPageCard{
    background-color: white;
    border: 1px solid #316412;
    border-radius: 10px;
    padding: 3%;
    /* width: 20%; */
    margin-bottom: 20px;
}
.collabLogo{
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.collabPageImages{
    height: 100%;
    width: 90%;
    border-radius: 20px;
    margin-left: -10%;
}
@media only screen and (max-width :768px) {
    .collabPageHeading{
        font-size:40px
    }
    .collagePageSectionPart{
        flex-direction: column;
    }
    .collabPageSection2Content{
        width: 90%;
        text-align: center;
        margin: 0 auto;
        text-align: justify;
    }
    .collabPageSection2Image{
        width: 250px;
        height:250px;
        text-align: center;
        margin: 0 auto;
    }
    .revertsections{
        flex-direction: column-reverse;
    }
}