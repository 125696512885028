.accPageSection1{
    width: 100%;
    background-image: url("../../assets/missionpageBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
}
.accPageBg{
    width: 100%;
    height: 40vh;
    object-fit: cover;
    background-repeat: no-repeat;
    filter: brightness(.8);
}
.accPageHeading{
    color: #fff;
    text-align: center;
    font-size: 3.5rem;
    font-family: Bold;
    padding: 7%;
}
.accPageSection2{
    width: 80%;
    padding: 2% 10%;
}
.accPageSectionHeading{
    font-size: 25px;
    font-family: Bold;
    margin-bottom: 15px;
    color: #316412;
    text-align: center;
    margin: 10px;
}
.accPageSectionContent{
    font-size: 16px;
    font-family: Regular;
    margin-bottom: 15px;
    line-height: 28px;
    text-align: center;
    margin: 2% 10%;
}
.accPageSection2Container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-flow: wrap;
}
.accPageSection2Card{
    background-color: white;
    border:1px solid #316412;
    border-radius: 20px;
    padding: 20px;
    width: 45%;
}
.accPageSection2CardHeading{
    font-size: 14px;
    font-family: SemiBold;
    margin-bottom: 15px;
    line-height: 28px;
    color: black;
    opacity: 0.7;
    margin-bottom: 15px;
}
.accPageSection2CardContent{
    font-size: 16px;
    font-family: Medium;
    margin-bottom: 15px;
    line-height: 28px;
}
.accPageSection2CardContent a{
    text-decoration: none;
    color: #316412;
}
.accPageBtn{
    border: none;
    background-color: #316412;
    color: white;
    font-size: 15px;
    font-weight: 500;
    font-family: "Bold";
    padding: 10px 30px;
    border-radius: 10px;
    margin: 10px 0px;
}
.accPageSection3{
    width: 80%;
    padding: 30px 10%;
    background-color: #E5E5E5;
}
.accPageSection4{
    width: 80%;
    padding: 2% 10%;
}
.accPageSection4Header{
    font-size: 25px;
    font-family: Bold;
    margin-bottom: 15px;
    color: #316412;
    text-align: center;
    margin: 25px;
}
.accPageSection4Heading{
    font-size: 16px;
    font-family: Bold;
    color: black;
    margin: 20px 0px;
}
.accPageSectionContentContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.accPageContent{
    font-size: 15px;
    font-family: Regular;
    margin: 20px 0px;
    line-height: 28px;
    width: 60%;
}
.accPageImage{
    width: 35%;
}

.slick-slide>div {
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    
}
.slider_card {
    background-color: #F5F5F5;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 300px !important;
    padding: 20px;
    margin: 10px;
    height: 280px;
}

.slider_card_img {
    width: 100%;
    height: 180px;
}

.slider_card_title {
    font-family: Bold;
    color: #45841F;
    font-size: 18px;
    margin-top: 5px;
}

.slider_card_description {
    font-family: Medium;
    color: black;
    font-size: 14px;
    margin:10px;
    height: 15%;
}

.slider_button {
    border: none;
    background-color: #316412;
    color: #fff;
    padding: 10px 15px;
    margin-top: 5px;
    font-size: 16px;
    font-family: "SemiBold";
    border-radius: 10px;
}
@media only screen and (max-width :768px) {
    .accPageSection2Card{
        width:95%;
        padding: 10px;
        text-align:left;
        margin-bottom: 10px;
    }
    .accPageSectionContent{
        text-align: justify;
    }
    .accPageSectionContentContainer{
        flex-direction: column;
        margin: 0 auto;
    }
    .accPageContent{
        width: 90%;
        text-align:justify;
    }
    .accPageImage{
        width: 90%;
        margin: 0 auto;
    }
    .revertsections{
        flex-direction: column-reverse;
    }
}