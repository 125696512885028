.Carousel_section {
  height: auto;
  width: 100%;
  color: #fff;
  text-decoration: none;
}
.slide {
  width: 100%;
  height: 100%;
}
/* Spinner styles */
.spinner {
  border: 10px solid rgba(255, 253, 253, 0.1);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 50px auto;
  border-left-color: #316412;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Carousel_section {
  position: relative;
}
.carousel-donate-button {
  position: absolute;
  border: none;
  background-color: #fff;
  color: #45841f;
  font-family: 'bold';
  font-size: 2.1rem;
  padding: 0.8rem 2rem;
  border-radius: 0.7rem;
  bottom: 25%;
  left: 45%;
}
@media screen and (max-width: 2000px) and (min-width: 1600px) {
  .carousel-donate-button {
    font-size: 1.8rem;
    padding: 0.8rem 1.2rem;
  }
}
@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .carousel-donate-button {
    font-size: 1.5rem;
    padding: 0.7rem 1.2rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: 800px) {
  .carousel-donate-button {
    font-size: 1.2rem;
    padding: 0.6rem 1rem;
  }
}
@media screen and (max-width: 800px) and (min-width: 500px) {
  .carousel-donate-button {
    font-size: 0.7rem;
    padding: 0.4rem 0.7rem;
    border-radius: 5px;
  }
}
@media screen and (max-width: 500px) and (min-width: 400px) {
  .carousel-donate-button {
    font-size: 0.5rem;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
  }
}
@media screen and (max-width: 400px) {
  .carousel-donate-button {
    font-size: 0.4rem;
    padding: 0.2rem 0.3rem;
    border-radius: 3px;
  }
}
/* .Carousel_components{
    width:53%;
    text-align: center;
    margin-top: 10vh;
    margin-left: 23vw;
}
.Carousel_title {
    font-size: 40px;
    font-family: Bold;
    margin-bottom: 15px;
}
.Carousel_description {
    font-size: 18px;
    font-family: Regular;
    margin-bottom: 15px;
    line-height: 28px;
}
.Carousel_button {
    border: none;
    background-color: #fff;
    color: #316412;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    font-family: "SemiBold";
    border-radius: 10px;
}
.Carousel_link {
    text-decoration: none;
    color: #316412;
    font-family: Medium;
}
.slick-dots li  button::before {
    top: -30px;
} */
/*@media screen and (max-width: 425px){
    .Carousel_section{
        height: 70vh;
    }
    .Carousel_components{
        margin-top: 5vh;
        width:70%;
        margin-left:15vw ;
    }
} */
