
.bttrVision{
    font-size: 18px;
    font-family: Regular;
    margin-bottom: 15px;
    line-height: 28px;
}
.missionPageBtn{
    border: none;
    background-color: #fff;
    color: #316412;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    font-family: "SemiBold";
    border-radius: 10px;
}
.link{
    text-decoration: none;
    color: #316412;
    font-family: Medium;
}
.missionPageSection2{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
}
.missionPageHeading{
    font-size: 25px;
    font-family: Bold;
    margin-bottom: 15px;
    color: #316412;
}
.missionPageContent{
    font-size: 16px;
    font-family: Regular;
    margin-bottom: 15px;
    line-height: 28px;
}
.missionPageSection3{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
}

.missionPageSection4{
    width: 80%;
    background-color: #E5E5E5;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
}
.missionPageSection5{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
}
.missionPageCard{
    background-color: #efefef;
    border-radius: 20px;
    width: 80%;
    z-index: 2;
    box-shadow: 5px 8px 10px rgb(233, 231, 231);
    margin: 15px;
    padding: 20px;
}
.missionPageCardContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 95%
}
.cardImage{
    width: 40%;
}
.cardContent{
    font-size: 14px;
    font-family: Regular;
    margin-bottom: 15px;
    line-height: 28px;
    width: 60%;
}
.cardHeader{
    font-size: 15px;
    font-family: SemiBold;
    margin-bottom: 15px;
    line-height: 28px;
}
.cardAlignment{
    display: flex;
    justify-content: right;
}
.missionPageImages{
    height: 90%;
    width: 90%;
    border-radius: 20px;
}
@media (max-width: 768px){
    .missionPageCardContent{
        flex-direction: column;
    }
    .cardAlignment{
        justify-content: center;
    }
    .cardImage{
        width: 250px;
        height: 250px;
        border-radius:20px
    }
    .cardContent{
        width: 90%;
    }
}

