.About_Section{
    display: flex;
    flex-direction: column;
    justify-content:center;
    font-family: "Regular";
    line-height: 30px;
    font-size:16px
}
.About{
    background-image: url("../../assets/abouBg.svg");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
   align-items: center;
}
.About_content{
    width: 100%;
   padding: 5vh 0vh;
    /* background-color: black; */
    text-align: center !important;
}
.About_title{
    font-family: Bold;
    font-size: 32px;
    line-height: 40px;
}
.About_description{
    line-height:24px;
    width: 60%;
    padding: 0 0 0 20%;
    /* width: 60%; */
    
}
/* .About_content{
    width: 60%;
    margin: 5vh 10%;
}
.About_title{
    font-family: Bold;
    font-size: 32px;
    line-height: 40px;
}
.About_description{
    line-height:24px;
    text-align: justify;
    width: 80%;
} */
.Contact_button{
    border: none;
    background-color: #fff;
    color: #316412;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    font-family: "SemiBold";
    border-radius: 10px;
}
.container_bg{
    background-color:#E5E5E5;
    width: 100%;
}
.About_flex_container{
    display:flex;
    flex-direction: row;
    width: 90%;
    margin: 50px auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
}
.BTTR_Project_director_image{
    height:250px;
    width: 250px;
    /* max-width: 100%;
    height: auto; */
}
.BTTR_Project_director_title{
    font-family: Bold;
    font-size: 24px;
}
.BTTR_Project_director_content{
    width:60%
}
.BTTR_Project_director_description{
    line-height: 27px;
    text-align: justify;
}

.our_Accomplishments {
    color: white;
    flex-shrink: 0;
    border-radius: 10px;
    background: #45841F;
    font-family: "Regular";
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
    .About_content{
        width: 90%;
    }
    .BTTR_Project_director_content{
        width: 80%;
    }
    .About_description{
        width: 80%;
        padding:0 0 0 10%
    }
    .revertSection{
        flex-direction: column-reverse;
    }
}