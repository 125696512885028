.nav-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}
.nav-row-container {
  background-color: #316412;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.nav-row-sub-container {
  display: flex;
  flex-direction: row;
  width: 90%;
}
.nav-column1 {
  color: white;
  font-family: 'Regular';
  font-size: large;
  width: 80%;
  margin-left: 10px;
}
.nav-column2 {
  display: flex;
  align-items: center;
  width: 13%;
  flex-direction: row;
  margin: 10px;
  /* column-gap: 30%; */
  justify-content: space-around;
}
@media screen and (max-width: 600px) {
  .nav-row-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (min-width: 1350) and (max-width: 600) {
    .nav-row-container {
      align-items: center;
    }
  }
  .nav-column1 {
    text-align: center;
    font-size: medium;
    width: 100%;
  }
  .nav-column1-heading {
    display: none;
  }
  .nav-column2 {
    width: 60%;
    justify-content: center;
  }
}
/* Second container starts here*/
.sub-nav-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.nav-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav-icon-column2 {
  display: flex;
  flex-direction: column;
}
.menubar {
  position: relative;
}
.navbar-navs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
  color: #000;
  font-size: 16px;
  font-family: 'Regular';
}
.bttr-text {
  color: #316412;
  font-size: 1.5 rem;
  font-family: Bold;
}
.logoIcon {
  height: 80px;
  width: 100px;
  margin-right: 20px;
}
.adopt-a-village-text {
  color: #d6a21d;
  font-size: 16px;
  font-family: 'Regular';
  font-weight: 700;
  margin-top: 10px;
}
.home-donate-button {
  border-radius: 10px;
  background: #ffff;
  color: #316412 !important;
  font-family: 'bold';
  text-decoration: none;
  font-size: large;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
}
.a {
  text-decoration: none;
  color: white !important;
}
.navbar {
  height: auto;
  background-color: #ffffff;
  position: relative;
  width: 100%;
}
.container {
  margin: 0 auto;
  padding: 0px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  font-size: 26px;
}
.donate-button {
  border: none;
  border-radius: 10px;
  background: #316412;
  color: #ffffff !important;
  font-family: 'Regular';
  font-size: large;
  padding: 10px 15px;
  margin: 10px;
}

.menu-icon {
  display: none;
}
.menuicon {
  height: 20px;
  width: 20px;
}

.nav-elements {
  width: 70%;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul a {
  font-size: 18px;
  font-weight: 300;
  font-family: Regular;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #316412;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #316412;
}
.mobile-navbar {
  width: 50% !important;
  margin: 0px auto 10px;
  text-align: center !important;
}

@media (max-width: 1024px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 80px;
    background-color: #ffffff;
    width: 0px;
    height: calc(100vh - 60px);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

}
@media (max-width: 426px) {
  .bttr-text {
    font-size: 1.1rem;
  }
  .adopt-a-village-text {
    font-size: small;
  }
}
@media (max-width: 320px) {
  .bttr-text {
    font-size: 0.9rem;
  }
  .adopt-a-village-text {
    font-size: 0.8rem;
  }
}
